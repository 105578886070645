<template>
    <div class="container">
        <div class="phone_container perform_box">
            <div class="perform_box">
                <div class="title">
                    <div class="title_txt flex_center_start_box">
                        <img src="../../assets/img/detail/title_ic5.png" />
                        Publications
                    </div>
                </div>
                <div class="perform_group flex_center_between_box phone_flex">
                    <div class="item flex_box">
                        <div class="img img1 flex_center">
                            <img src="../../assets/img/detail/pub_img1.png" />
                        </div>
                        <div class="des">
                            <div class="title">Bankruptcy</div>
                            <div class="txt">{{ obj.Bankruptcy }}</div>
                        </div>
                    </div>
                    <div class="item flex_box">
                        <div class="img img2 flex_center">
                            <img src="../../assets/img/detail/pub_img2.png" />
                        </div>
                        <div class="des">
                            <div class="title">Litigations</div>
                            <div class="txt">{{ obj.Litigations }}</div>
                        </div>
                    </div>
                    <div class="item flex_box">
                        <div class="img img3 flex_center">
                            <img src="../../assets/img/detail/pub_img3.png" />
                        </div>
                        <div class="des">
                            <div class="title">Sanctions</div>
                            <div class="txt">{{ obj.Sanctions }}</div>
                        </div>
                    </div>
                    <div class="item flex_box">
                        <div class="img img4 flex_center">
                            <img src="../../assets/img/detail/pub_img4.png" />
                        </div>
                        <div class="des">
                            <div class="title">Lien</div>
                            <div class="txt">{{ obj.Lien }}</div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="top_tab">
                <el-tabs v-model="tabActiveName" type="card" class="tab_box" @tab-click="handleTabClick">
                    <el-tab-pane label="Litigations" name="2" class="result_tab">
                        <div class="table_box">
                            <el-table ref="litiTable" class="computer_right_cont_box" :data="litiTable" border style="width: 100%">
                                <!-- 空数据状态的插槽 -->
                                <template slot="empty">No data</template>
                                <el-table-column label="Filed Date" prop="filedDate" align="left" show-overflow-tooltip :resizable="false" />
                                <el-table-column label="Case Type" prop="caseType" align="left" show-overflow-tooltip :resizable="false" />
                                <el-table-column label="Role" prop="role" align="left" show-overflow-tooltip :resizable="false" />
                                <el-table-column label="Litigant" prop="litigant" align="left" show-overflow-tooltip :resizable="false" />
                                <el-table-column label="Sanctions Cause of Action" prop="causeAction" align="left" show-overflow-tooltip :resizable="false" class-name="product" width="224">
                                    <template slot-scope="scope">
                                        <div class="ellipsis">{{ scope.row.causeAction }}</div>
                                    </template>
                                </el-table-column>
                                <el-table-column label="Location" prop="location" align="left" show-overflow-tooltip :resizable="false" />
                            </el-table>
                            <!-- 手机端 -->
                            <div class="phone_page_bg">
                                <div class="table_box">
                                    <ul class="phone_table_box" v-if="litiTable.length>0">
                                        <li class="phone_table_box_item"  v-for="(item, index) in litiTable" :key="index">
                                            <div class="phone_table_box_item_right">
                                                <!-- <h1 class="tbl_company line_1" @click="setid(item)"  > {{item.name}}</h1> -->
                                                <h2 class="line_1">Filed Date: {{ item.filedDate }}</h2>
                                                <h2 class="line_1">
                                                    <span>Case Type:{{ item.caseType }}</span>
                                                </h2>
                                                <h2 class="line_1">
                                                    <span>Role:{{ item.role }}</span>
                                                </h2>
                                                <h2 class="line_1">
                                                    <span>Litigant:{{ item.litigant }}</span>
                                                </h2>
                                                <h2 class="line_1">
                                                    <span>Sanctions Cause of Action:{{ item.causeAction }}</span>
                                                </h2>
                                            </div>
                                        </li>
                                    </ul>
                                    <div v-else class="nodada">No Data</div>
                                </div>
                            </div>
                            <div class="page_i_box">
                                <Page :totalFont="true" :total="page.total" @onPageChange="onPageChange" @currtentPageChange="currtentPageChange"></Page>
                                <div class="search_rsult_txt line_2">{{ page.pageNo }}-{{ page.pageSize }} of over {{ page.total | FilterNum }} results</div>
                            </div>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="Sanctions" name="3" class="result_tab">
                        <div class="table_box">
                            <el-table ref="sanctionsTable" :data="sanctionsTable" border style="width: 100%" class="no_page computer_right_cont_box">
                                <!-- 空数据状态的插槽 -->
                                <template slot="empty">No data</template>
                                <el-table-column label="Date" prop="date" align="left" show-overflow-tooltip :resizable="false" />
                                <el-table-column label="File No." prop="fileNo" align="left" show-overflow-tooltip :resizable="false" />
                                <el-table-column label="Case Type" prop="caseType" align="left" show-overflow-tooltip :resizable="false" />
                                <el-table-column label="Party" prop="party" align="left" show-overflow-tooltip :resizable="false" />
                                <el-table-column label="Secured Party" prop="securedParty" align="left" show-overflow-tooltip :resizable="false" />
                                <el-table-column label="Location" prop="location" align="left" show-overflow-tooltip :resizable="false" />
                            </el-table>

                            <!-- 手机端 -->
                            <div class="phone_page_bg">
                                <div class="table_box">
                                    <ul class="phone_table_box">
                                        <li class="phone_table_box_item" v-for="(item, index) in sanctionsTable" :key="index">
                                            <div class="phone_table_box_item_right">
                                                <!-- <h1 class="tbl_company line_1" @click="setid(item)"  > {{item.name}}</h1> -->
                                                <h2 class="line_1">Date: {{ item.date }}</h2>
                                                <h2 class="line_1">
                                                    <span>File No.:{{ item.fileNo }}</span>
                                                </h2>
                                                <h2 class="line_1">
                                                    <span>Case Type:{{ item.caseType }}</span>
                                                </h2>
                                                <h2 class="line_1">
                                                    <span>Party:{{ item.party }}</span>
                                                </h2>
                                                <h2 class="line_1">
                                                    <span>Secured Party:{{ item.securedParty }}</span>
                                                </h2>
                                                <h2 class="line_1">
                                                    <span>Location:{{ item.location }}</span>
                                                </h2>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="Lien" name="4" class="result_tab">
                        <div class="table_box">
                            <el-table ref="lienTable" :data="lienTable" border style="width: 100%" class="no_page computer_right_cont_box">
                                <!-- 空数据状态的插槽 -->
                                <template slot="empty">No data</template>
                                <el-table-column label="Filing Date" prop="fileDate" align="left" show-overflow-tooltip :resizable="false" />
                                <el-table-column label="Filing Number" prop="fileNumber" align="left" show-overflow-tooltip :resizable="false" />
                                <el-table-column label="Case Type" prop="caseType" align="left" show-overflow-tooltip :resizable="false" />
                                <el-table-column label="Secured Party" prop="securedParty" align="left" show-overflow-tooltip :resizable="false" />
                            </el-table>
                            <!-- 手机端 -->
                            <div class="phone_page_bg">
                                <div class="table_box">
                                    <ul class="phone_table_box">
                                        <li class="phone_table_box_item" v-for="(item, index) in lienTable" :key="index">
                                            <div class="phone_table_box_item_right">
                                                <!-- <h1 class="tbl_company line_1" @click="setid(item)"  > {{item.name}}</h1> -->
                                                <h2 class="line_1">Filing Date: {{ item.fileDate }}</h2>
                                                <h2 class="line_1">
                                                    <span>Filing Number:{{ item.fileNumber }}</span>
                                                </h2>
                                                <h2 class="line_1">
                                                    <span>Case Type:{{ item.caseType }}</span>
                                                </h2>

                                                <h2 class="line_1">
                                                    <span>Secured Party:{{ item.securedParty }}</span>
                                                </h2>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="Bankruptcy" name="1" class="result_tab">
                        <div class="table_box">
                            <el-table ref="BankruptcyTable" :data="BankruptcyTable" border style="width: 100%" class="no_page computer_right_cont_box">
                                <!-- 空数据状态的插槽 -->
                                <template slot="empty">No data</template>
                                <el-table-column label="Title" prop="title" align="left" show-overflow-tooltip :resizable="false" />
                                <el-table-column label="Case Number" prop="caseNumber" align="left" show-overflow-tooltip :resizable="false" />
                                <el-table-column label="Court" prop="court" align="left" show-overflow-tooltip :resizable="false" />
                                <el-table-column label="Status" prop="status" align="left" show-overflow-tooltip :resizable="false" />
                            </el-table>
                            <!-- 手机端 -->
                            <div class="phone_page_bg">
                                <div class="table_box">
                                    <ul class="phone_table_box">
                                        <li class="phone_table_box_item" v-for="(item, index) in BankruptcyTable" :key="index">
                                            <div class="phone_table_box_item_right">
                                                <!-- <h1 class="tbl_company line_1" @click="setid(item)"  > {{item.name}}</h1> -->
                                                <h2 class="line_1">Title: {{ item.title }}</h2>
                                                <h2 class="line_1">
                                                    <span>Case Number:{{ item.caseNumber }}</span>
                                                </h2>
                                                <h2 class="line_1">
                                                    <span>Court:{{ item.court }}</span>
                                                </h2>

                                                <h2 class="line_1">
                                                    <span>Status:{{ item.status }}</span>
                                                </h2>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </el-tab-pane>
                </el-tabs>
            </div>
        </div>
    </div>
</template>
<script>
import Page from '@/components/page';
import { getPublications } from '@/api/companyApi.js';
import crypto from '@/utils/crypto';
import { mixins1 } from '@/mixins/index';
export default {
    mixins: [mixins1],
    components: { Page },
    data() {
        return {
            page: {
                pageNo: 1,
                pageSize: 10,
                total: 0,
            },
            tabActiveName: '2',
            obj: {
                Bankruptcy: '',
                Litigations: '',
                Sanctions: '',
                Lien: '',
            },
            litiTable: [],
            sanctionsTable: [],
            lienTable: [],
            BankruptcyTable: [],
        };
    },
    computed: {},
    watch: {},
    created() {
        this.getData(2);
    },
    methods: {
        getData(type) {
            const id3a = this.$route.query.id3a;
            const companyCountry = this.$route.query.companyCountry;

            const base = JSON.parse(sessionStorage.getItem('base'));
            let params = 'aaaId=' + id3a + '&countryCode=' + companyCountry + '&type=' + type;

            getPublications(params).then(result => {
                let data = JSON.parse(crypto.decrypt(result));
                if (data && data.code && data.data) {
                    this.tableData = data.data.ligitations;
                    this.sanctionsTable = data.data.sanctions;
                    this.lienTable = data.data.liens;
                    this.BankruptcyTable = data.data.bankruptcies;
                    this.obj = {
                        Bankruptcy: data.data.hightlight.bankruptcy,
                        Litigations: data.data.hightlight.litigations,
                        Sanctions: data.data.hightlight.sanctions,
                        Lien: data.data.hightlight.lien,
                    };

                    this.litiTable = this.tableData ? this.tableData.slice(0, this.page.pageSize) : [];
                    this.page.total = this.tableData.length;
                }
            });
        },
        //分页
        onPageChange(pageNo) {
            this.page.pageNo = pageNo;
            if (pageNo == 1) {
                this.litiTable = this.tableData.slice(0, this.page.pageSize);
                return;
            }
            const startSize = (pageNo - 1) * this.page.pageSize;
            this.litiTable = this.tableData.slice(startSize, startSize + this.page.pageSize);
        },
        currtentPageChange(pageSize) {
            this.page.pageSize = pageSize;
            const pageNo = this.page.pageNo;

            if (pageNo == 1) {
                this.litiTable = this.tableData.slice(0, this.page.pageSize);
                return;
            }
            const startSize = (pageNo - 1) * this.page.pageSize;
            this.litiTable = this.tableData.slice(startSize, startSize + this.page.pageSize);
        },
        //tab
        handleTabClick() {
            this.getData(this.tabActiveName);
        },
    },
};
</script>
<style scoped>
.cont_m {
    margin-top: 30px;
    margin-bottom: 80px;
}
.perform_box {
    margin-top: 60px;
}
.title {
    margin-bottom: 20px;
}
.title_txt {
    font-size: 16px;
    font-family: 'Arial Bold';
}
.title_txt img {
    width: 20px;
    margin-right: 8px;
}
.perform_group .item {
    width: 230px;
    height: 70px;
    padding: 14px 20px;
    background: #ffffff;
    box-shadow: 0px 2px 6px 1px rgba(10, 33, 57, 0.14);
    border-radius: 14px;
}
.perform_group .item .img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}
.perform_group .item .img img {
    width: 20px;
    height: 20px;
}
.perform_group .item .img1 {
    background: #1290c9;
}
.perform_group .item .img2 {
    background: #55b419;
}
.perform_group .item .img3 {
    background: #ff7600;
}
.perform_group .item .img4 {
    background: #f56b6b;
}
.perform_group .item .des {
    margin-left: 10px;
}
.perform_group .item .des .title {
    font-size: 12px;
    color: #8497ab;
    margin-bottom: 8px;
}
.perform_group .item .des .txt {
    font-size: 16px;
    color: #022955;
    font-family: 'Arial Bold';
}
.tab_box {
    margin-top: 40px;
}
/* tab */
.tab_box /deep/ .el-tabs__header {
    margin-bottom: 10px;
}
.el-tabs--card > .el-tabs__header {
    border: none;
}
.tab_box /deep/ .el-tabs__header,
.tab_box /deep/.el-tabs__content {
    width: 100%;
    box-shadow: 0px 2px 6px 1px rgba(10, 33, 57, 0.14);
    border-radius: 14px;
}
.tab_box /deep/ .el-tabs__header {
    padding: 11px 75px;
}
.result_tab {
    padding: 20px;
}
.tab_box /deep/ .el-tabs__nav-wrap {
    width: 100%;
    height: 28px;
    background: #f2f5f9;
    border-radius: 8px;
    border: 1px solid #e7eaed;
}
.tab_box /deep/ .el-tabs__nav {
    width: 100%;
    border: none;
    padding: 2px 75px;
    display: flex;
    justify-content: space-between;
}
.tab_box /deep/ .el-tabs__item {
    padding: 0 15px;
    width: 150px;
    height: 20px;
    line-height: 20px;
    font-size: 12px;
    color: #8497ab;
    border: none;
    text-align: center;
}
.tab_box /deep/ .el-tabs__item:last-child {
    padding-right: 15px !important;
}
.tab_box /deep/ .el-tabs__item.is-active {
    background: #ff7600;
    box-shadow: 0px 1px 2px 0px #c44306;
    border-radius: 4px;
    font-family: 'Arial Bold';
    color: #ffffff;
}
.market_box {
    display: flex;
    justify-content: space-between;
}
.tab_box /deep/ .el-table--scrollable-x .el-table__body-wrapper {
    overflow: hidden;
}
.tab_box /deep/ .el-table__body,
.tab_box .el-table__footer,
.tab_box /deep/ .el-table__header {
    width: auto !important;
}
.ellipsis {
    display: -webkit-box;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: break-spaces;
}
@media (max-width: 821px) {
    .phone_table_box_item {
        display: flex;
        /* justify-content: center; */
        /* align-items: center; */
        padding: 20px 0;
        border-bottom: 1px solid #e0dce5;
    }
    .table_box .phone_table_box_item img {
        width: 28px;
        height: 17px;
        flex: none;
        margin-right: 4px;
        box-shadow: 0px 1px 2px 0px rgb(0 0 0 / 20%);
    }
    .phone_table_box_item_right {
        font-size: 14px;
        margin-left: 20px;
        width: 85%;
    }
    .phone_table_box_item_right h1 {
        font-size: 14px;
        font-weight: normal;
    }
    .phone_table_box_item_right h2 {
        font-size: 12px;
        color: #656373;
        font-weight: normal;
        line-height: 22px;
    }
    .top_tab {
        margin-top: 20px !important;
    }
    .tab_box {
        margin-bottom: 10px;
        width: 100%;
        padding: 10px;
        /* padding: 20px; */
        box-shadow: 0px 2px 6px 1px rgba(10, 33, 57, 0.14);
        border-radius: 14px;
        background: #ffffff;
        margin-top: 10px !important;
    }
    .tab_box /deep/ .el-tabs__header {
        padding: 11px 5px !important;
    }
    .tab_box /deep/ .el-tabs__nav {
        padding: 4px 5px !important;
    }
    .phone_table_box_item_right .line_1 {
        white-space: nowrap !important;
    }
    .perform_group .item {
        width: 47% !important;
        height: 50px !important;
        margin: 10px 0px;
        padding: 10px 19px !important;
    }
    .phone_flex {
        flex-wrap: wrap;
    }
    .perform_group .item .img {
        height: 20px !important;
        width: 20px !important;
    }
  
    .perform_box {
        margin-top: 20px !important;
    }
    .phone_container {
        width: 100%;
        padding: 10px;
        /* padding: 20px; */
        box-shadow: 0px 2px 6px 1px rgba(10, 33, 57, 0.14);
        border-radius: 14px;
        background: #ffffff;
    }
     .perform_group .item .des .txt{
      font-size: 12px !important;
    }
    .flex_box{
      align-items: center;
    }
    .nodada{
          text-align: center;
    font-size: 14px;
    line-height: 20px;
    color: #cccc;
    }
}
</style>
